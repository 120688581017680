<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import TabPersonalInfo from "@/views/pages/tutor/tabs-personal-info";
import TabServices from "@/views/pages/tutor/tabs-services";
import TabQualification from "@/views/pages/tutor/tabs-qualifications";
import TabAssignment from "@/views/pages/tutor/tabs-assignment";

export default {
	components: {
		Layout,
		PageHeader,
		ConfirmationModal,
		MessageModal,
		TabPersonalInfo,
		TabServices,
		TabQualification,
		TabAssignment
	},
	data() {
		const id = this.$route.params.id;
        let modeName;
		
		if (this.$route.path.includes('view')) {
			modeName = 'View';
		} else {
			modeName = id === 'add' ? 'Add' : 'Edit';
		}

		return {
			id: id,
			title: `${modeName} Service Professional`,
			modeName,
			items: [
				{
					text: "Service Professional"
				},
				{
					text: `${modeName}`,
					active: true,
				},
			],
			data: {
				name: '',
				nric: '',
				gender: '',
				phone: '',
				email: '',
				dob: '',
				age: '',
				application_status: '',
				nationality: '',
				special_needs_exp: '',
				services: {},
				service_providers: {},
				docs: {},
				profile_pic: [],
				edu_qualification: [],
				specialised_cert: [],
				service_details: {},
				assignments: {},
				remarks: '',
				reside_in_sg: '',
				highest_education_qualification: '',
				is_blacklisted: '',
				service_provided: '',
			},
			serviceOptions: [],
			serviceProviderOptions: [],
			errors: {},
		};
	},
	async mounted() {
		this.retrieveServices().then((services) => {
			this.serviceOptions = services.map((service) => ({
				name: service.name,
				value: service.id,
			}));
		});

		this.retrieveServiceProviders().then((service_providers) => {
			this.serviceProviderOptions = service_providers.map((service_provider) => ({
				name: service_provider.name,
				value: service_provider.id,
			}));
		});

		if (this.modeName !== 'Add') {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/tutor/${this.id}`, {
                headers: authHeader(),
            });

            if (result && result.data['code'] === 'success') {
                const data = result.data['data']['tutor'];
				console.log('result', result)
                for (const [key, item] of Object.entries(data)) {
                    if (key in this.data) {
                        this.data[key] = item;
                    }

					if (key == 'docs') {
						item.forEach(doc => {
							if (doc.for == 'profile_pic') {
								this.data['profile_pic'].push(doc);
							} else if (doc.for == 'edu_qualification') {
								this.data['edu_qualification'].push(doc);
							} else if (doc.for == 'specialised_cert') {
								this.data['specialised_cert'].push(doc);
							}
						});
					}
                }
				console.log('this.data', this.data)
            }
        }
	},
	methods: {
		async retrieveServices() {
            this.loading = true;
            let services = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/service`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    const serviceDetails = result['data'].services;
                    services = serviceDetails.filter(service => service.status == 1);
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return services;
        },

		async retrieveServiceProviders() {
            this.loading = true;
            let service_providers = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/service_provider`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    const serviceProviderDetails = result['data'].service_providers;
                    service_providers = serviceProviderDetails.filter(type => type.status == 1);
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return service_providers;
        },

		async approve() {
            const requestData = {};
            requestData.status = 'approved';

            const updateCallback = async () => {
                const result = await axios.post(`${process.env.VUE_APP_APIURL}/tutor/${this.id}`, requestData, {
                    headers: authHeader(),
                });

                if (result) {
                    this.$refs.confirmationModal.closeModal();
                    if (result.data['code'] === 'success') {
                        this.$refs.messageModal.showModal('Record has been updated successfully');
                    } else {
                        this.$refs.messageModal.showModal('Something went wrong. Please try again later.');
                    }
                }   
			};
			this.$refs.confirmationModal.showModal('Approve this request?', updateCallback);
        },

        async decline() {
            const requestData = {};
            requestData.status = 'rejected';
            
            const updateCallback = async () => {
                const result = await axios.post(`${process.env.VUE_APP_APIURL}/tutor/${this.id}`, requestData, {
                    headers: authHeader(),
                });

                if (result) {
                    this.$refs.confirmationModal.closeModal();
                    if (result.data['code'] === 'success') {
                        this.$refs.messageModal.showModal('Record has been updated successfully');
                    } else {
                        this.$refs.messageModal.showModal('Something went wrong. Please try again later.');
                    }
                }   
			};
			this.$refs.confirmationModal.showModal('Decline this request?', updateCallback);
        }
	}
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<ConfirmationModal ref="confirmationModal" />
		<MessageModal ref="messageModal" />
    
		<b-tabs active>
			<b-tab title="Personal Info">
				<TabPersonalInfo :tutorInfo="this.data" />
			</b-tab>

			<b-tab title="Services">
				<TabServices :tutorInfo="this.data" :serviceOptions="serviceOptions" :serviceProviderOptions="serviceProviderOptions" />
			</b-tab>

			<b-tab title="Qualification & Experience">
				<TabQualification :tutorInfo="this.data" :serviceOptions="serviceOptions" />
			</b-tab>

			<b-tab title="Assignments">
				<TabAssignment :tutorInfo="this.data" />
			</b-tab>
		</b-tabs>

		<div class="buttons" v-if="data.application_status === 'pending'">
			<button class="decline" @click="decline">Decline</button>
			<button class="approve" @click="approve">Approve request</button>
		</div>
	</Layout>
</template>

<style scoped>
.buttons {
    background-color: #ffffff;
    padding: 24px 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .decline {
        background-color: #F2F2F2;
        border: 1px solid #F2F2F2;
        border-radius: 6px;
        padding: 9px 20px;
        font-family: Nunito;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #505D69;
    }

    .approve {
        background-color: #219653;
        border: 1px solid #219653;
        border-radius: 6px;
        padding: 9px 20px;
        font-family: Nunito;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
    }
}
</style>